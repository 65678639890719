import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import useBaseUtils from "~/functions/useBaseUtils";
import { valuationTemplate } from "~/utils/templates/valuation_template";
import glossary from "~/utils/templates/glossary";
export default defineComponent({
  props: {
    dateArr: {
      type: Array,
      "default": function _default() {
        return [{
          periodenddate: "date for column header for period",
          // text: formatDate.format(parseTime(d.periodenddate)) parseTime(d.periodenddate) results in an epoch?
          estimateperiodid: "unique date value",
          // value: "unique date value", // comes from estimateperiodid
          calendaryear: 2020,
          // makes sense to have both I guess
          calendarquarter: 4,
          // for only showing annual periods..
          relativeconstant: "unique relConst",
          periodtypeid: "idk whats up with this" // doesn't appear to be used in the multiples table
        }];
      }
    }
  },
  setup: function setup() {
    var _useDateTimeFormatter = useDateTimeFormatters(),
      parseUtcDateTime = _useDateTimeFormatter.parseUtcDateTime,
      utcEpochToShortDate = _useDateTimeFormatter.utcEpochToShortDate;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn;
    var tblTemplateArr = valuationTemplate.map(function (t) {
      return t.keys;
    });
    var activeStatement = ref(0);
    var showTurns = ref(true); // console.log("ntmTemplate: ", valuationTemplate)
    var rowToSelectedKey = function rowToSelectedKey(row) {
      // convert the finRow data to a unique rowId for the financial
      // data that will be used as the key to store the rest of the data
      var formula = row.formula;
      var dataitemid = [];
      if (typeof row.dataitemid === "number") {
        // can concat and return
        dataitemid.push(row.dataitemid.toString());
      } else {
        // dataitemid is an object of strings, concat the strings with formula
        row.dataitemid.forEach(function (s) {
          dataitemid.push(s);
        });
      }
      return formula + "-" + dataitemid.join("-");
    };
    var generateRowClasses = function generateRowClasses(row) {
      var classArray = row.format.split(" ");
      classArray.push("datarow");
      return classArray;
    };
    return {
      ntmTemplate: valuationTemplate,
      tblTemplateArr: tblTemplateArr,
      glossary: glossary,
      parseUtcDateTime: parseUtcDateTime,
      utcEpochToShortDate: utcEpochToShortDate,
      activeStatement: activeStatement,
      showTurns: showTurns,
      rowToSelectedKey: rowToSelectedKey,
      generateRowClasses: generateRowClasses,
      I18nFn: I18nFn
    };
  },
  computed: {
    datObj: function datObj() {
      var tid = this.tid;
      var ciqKey = "addMultiples";
      if (tid) {
        try {
          return this.$store.state.ciq[ciqKey][tid] || {};
        } catch (error) {
          console.error("No Multiples datObj: ", error);
          return {};
        }
      } else {
        return {};
      }
    },
    ticker: function ticker() {
      return this.$store.state.ciq.ticker || {};
    },
    tid: function tid() {
      return this.ticker.tradingitemid;
    },
    loading: function loading() {
      var loadingKey = "fetchingValuation";
      return this.$store.state.ciq[loadingKey];
    },
    dates: function dates() {
      return this.datObj.dates || [];
    },
    tblDataObj: function tblDataObj() {
      return this.datObj.tblDataObj || {};
    },
    dateHeaders: function dateHeaders() {
      var _this = this; // find Date Arr
      // const activeStatement = this.activeStatement
      // const period = this.period
      // const finData = this.data
      var dates = this.dates || []; // if (finData[period]) {
      //   // if statement allows dates to be pulled from
      //   // various locations this.$store.state.ciq
      //   dates = finData[period].dates || []
      // }
      var periodId = new Set(); /**
                                * The dates array needs to be on the dates key
                                * headers needs to have
                                * estimateperiodid -> relativeconstant ... key to extract data
                                * d.periodenddate to make label -> quarterenddate or pricing date if relconst = 500
                                *
                                * basically the date object needs to contain the information to
                                * make the rendered label and the information that acts as
                                * the key to extract the data from the lineitem object
                                */
      var headers = dates.reduce(function (acc, d) {
        // TODO: you've need to figure this out,
        // dateHeaders wasn't displaying the last arr becuse of this code
        // you were worried two estimate periods would show up?
        // or were you trying to manage DOM update becasue :key=date.value which was d.estimateperiodid
        // if (periodId.has(d.estimateperiodid)) {
        //   return acc
        // } else {
        // }
        periodId.add(d.estimateperiodid);
        var utcEpoch = _this.parseUtcDateTime(d.fiperiodenddate);
        var data = Object.assign({}, d, {
          text: _this.utcEpochToShortDate.format(utcEpoch),
          value: d.fiperiodenddate,
          epoch: utcEpoch,
          relativeconstant: d.relativeconstant
        });
        acc.push(data);
        return acc;
      }, []);
      if (this.reverseDates && this.activeStatement === 0) {
        return headers.reverse();
      } else {
        return headers;
      }
    }
  }
});